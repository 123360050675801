import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { NavigationService } from '../navigation-service/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    status?: Status;

    private monitorSubscription!: Subscription;
    private returnUrl: string | null = null;

    constructor(private router: Router) {}

    public async getStatus(): Promise<Status> {
        return new Promise<Status>((resolve, reject) => {
            var xhttp = new XMLHttpRequest();
            const self = this;
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        resolve(JSON.parse(this.responseText));
                    } else {
                        reject();
                    }
                }
            };
            xhttp.open("GET", `${location.origin}/api/status`, true);
            xhttp.send();
        });
    }

    private checkMaintenance(previousStatus?: Status) {
        if(this.status) {
            // Site under maintenance?
            if (this.status.EnableMaintenance) {
                if (!window.location.pathname.endsWith("/maintenance")) {
                    this.returnUrl = this.router.url;
                    this.router.navigate(NavigationService.MiscRoutes.Maintenance());
                }
            }
            else {
                // Return from maintenance view?
                if (window.location.pathname.endsWith("/maintenance")) {
                    this.router.navigateByUrl(this.returnUrl || "/");
                    this.returnUrl = null;
                }

                // Revision changed?   
                if (previousStatus && this.status.GitRevisionHash != previousStatus.GitRevisionHash) {
                    window.location.reload();
                }
            }
        }
    }

    public async startMonitoring(frequency = 60) {
        const loop = async () => {
            try {
                const previousStatus = this.status ? Object.assign({}, this.status) : undefined;
                this.status = await this.getStatus();
                this.checkMaintenance(previousStatus);
            }
            catch { }
        }    
        loop();
        this.monitorSubscription = interval(frequency * 1000)
            .subscribe(async () => {
                loop();
            });
    }

    public stopMonitoring() {
        if (this.monitorSubscription && !this.monitorSubscription.closed)
            this.monitorSubscription.unsubscribe();
    }
}

export interface Status {
    GitRevisionHash: string;
    EnableMaintenance?: boolean;
}