import * as moment from 'moment';
import { LocalizedKeyString } from 'projects/api-client/src/models/common/LocalizedKeyString';
import { LocalDate } from 'projects/api-client/src/types/LocalDate';

export class SortService {

    constructor() { }

     static getStringComparator<T, K extends keyof T>(key?: K, direction: "ASC" | "DESC" = "ASC"): ((a: T, b: T) => number) {
        return (a: T, b: T): number => {
            let strA: string;
            let strB: string;

            // If a key is provided, assume the input is an array of objects and sort by the specified key.
            if (key) {
                strA = (a[key] as unknown as string).toLowerCase();
                strB = (b[key] as unknown as string).toLowerCase();
            } else {
                // If no key is provided, assume the input is a simple string array.
                strA = (a as unknown as string).toLowerCase();
                strB = (b as unknown as string).toLowerCase();
            }

            return strA.localeCompare(strB, undefined, { sensitivity: "accent" }) * (direction === "ASC" ? 1 : -1);
        };
    }

    static getLocalizedKeyStringComparator<T, K extends keyof T>(key: K, direction: "ASC" | "DESC" = "ASC", use: "source" | "target" = "target"): ((a: T, b: T) => number) {
        return (a: T, b: T): number => {
            const strA = <unknown>a[key] as LocalizedKeyString;
            const strB = <unknown>b[key] as LocalizedKeyString;

            if (use === "source") {
                if (strA.key === null) return (direction === "ASC") ? 1 : -1;
                if (strB.key === null) return (direction === "ASC") ? -1 : 1;
                return strA.key.localeCompare(strB.key, undefined, { sensitivity: "accent" }) * (direction === "ASC" ? 1 : -1);
            }
            else {
                if (strA.text === null) return (direction === "ASC") ? 1 : -1;
                if (strB.text === null) return (direction === "ASC") ? -1 : 1;
                return strA.text.localeCompare(strB.text, undefined, { sensitivity: "accent" }) * (direction === "ASC" ? 1 : -1);
            }
        };
    }

    static getDateComparator<T, K extends keyof T>(key: K, direction: "ASC" | "DESC" = "ASC"): ((a: T, b: T) => number) {
        // used to handle undefined dates
        const distantPast = moment().add(-99, 'year').valueOf();

        return (a: T, b: T): number => {
            let dateA = <unknown>a[key] ? moment(<unknown>a[key] as string).valueOf() : distantPast;
            let dateB = <unknown>b[key] ? moment(<unknown>b[key] as string).valueOf() : distantPast;
            return (dateA - dateB) * (direction === "ASC" ? 1 : -1);
        };
    }

    static getLocalDateComparator<T, K extends keyof T>(key: K, direction: "ASC"|"DESC" = "ASC"): ((a: T, b: T) => number) {
        return (a: T, b: T): number => {
            return (new Date((<unknown>a[key] as LocalDate).toJSON()).valueOf() - new Date((<unknown>b[key] as LocalDate).toJSON()).valueOf()) * (direction === "ASC" ? 1:-1);
        };
    }

    static getNumberComparator<T, K extends keyof T>(key: K, direction: "ASC"|"DESC" = "ASC"): ((a: T, b: T) => number) {
        return (a: T, b: T): number => {
            return ((<unknown>a[key] as number) - (<unknown>b[key] as number)) * (direction === "ASC" ? 1:-1);
        };
    }
    
    static getNullableBooleanComparator<T, K extends keyof T>(key: K, direction: "ASC" | "DESC" = "ASC"): ((a: T, b: T) => number) {
        return (a: T, b: T): number => {
            const aValue = a[key] as boolean | null;
            const bValue = b[key] as boolean | null;
    
            if (aValue === null && bValue === null) {
                return 0;
            }
            if (aValue === null) {
                return 1;
            }
            if (bValue === null) {
                return -1;
            }
    
            if (aValue === bValue) {
                return 0;
            }
    
            return direction === "ASC" ? (aValue ? -1 : 1) : (aValue ? 1 : -1);
        };
    }
}