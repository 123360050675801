import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    settings!: AppSettings;

    constructor() { }

    public async loadSettings(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            var xhttp = new XMLHttpRequest();
            const self = this;
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        self.settings = JSON.parse(this.responseText);
                        resolve();
                    } else {
                        reject();
                    }
                }
            };
            xhttp.open("GET", `${location.origin}/api/app-settings`, true);
            xhttp.send();
        });
    }
}

export interface AppSettings {
    InstanceId: string;
    Environment: string;
    AwsRegion: string;
    MainApiBaseUrl: string;
    SentryDsn: string | null;
    AssetsBaseUrl: string;
}