import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ApplicationRole } from '../models/common/ApplicationRole';
import { PlatformLanguageCode } from '../models/common/PlatformLanguageCode';
import { AccountAPIService } from '../api/account.service';
import { LocalDate } from '../types/LocalDate';
import { ApplicationPermission } from '../models/common/ApplicationPermission';
import { GenerateEmailVerificationCodeCommandDTO } from '../models/account/commands/GenerateEmailVerificationCodeCommandDTO';
import { NavigationService } from 'src/app/shared/services/navigation-service/navigation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private apiToken: ApiToken | undefined;

    private currentUserContextChangedBS = new BehaviorSubject<UserContext | null>(null);
    public currentUserContextChanged = this.currentUserContextChangedBS.asObservable();
    public get currentUserContext(): UserContext | null {
        return this.currentUserContextChangedBS.value;
    }

    public get isAuthenticated(): boolean { return !!this.apiToken; }

    constructor(private accountService: AccountAPIService,
        private router: Router) {
        this.currentUserContextChanged.subscribe()
    }

    public async signIn(email: string, password: string, totp?: string) {
        const token = await this.accountService.signin({
            email, password, totp, remember_me: true
        }).toPromise();
        await this.signinWithToken(ApiToken.fromToken(token.access_token, token.token_type, token.expires_in));
    }

    public async signinWithGoogle(id_token: string) {
        const token = await this.accountService.googleSignin({
            id_token,
            remember_me: true
        }).toPromise();
        await this.signinWithToken(ApiToken.fromToken(token.access_token, token.token_type, token.expires_in));
    }

    public async signinWithFacebook(access_token: string) {
        const token = await this.accountService.facebookSignin({
            access_token,
            remember_me: true
        }).toPromise();
        await this.signinWithToken(ApiToken.fromToken(token.access_token, token.token_type, token.expires_in));
    }

    private async signinWithToken(token: ApiToken) {
        this.apiToken = token;
        await this.getUserContext();
    }

    public async getSigninModes(email: string) {
        return await this.accountService.getSigninModes({ 'email': email }).toPromise();
    }

    public async getUserContext(): Promise<UserContext> {

        const profile = await this.accountService.profileGet().toPromise();

        const userContext: UserContext = {
            id: profile.id,
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            phone: profile.phone,
            date_of_birth: LocalDate.from(profile.date_of_birth),
            has_password: profile.has_password,
            enable_2fa: profile.enable_2fa,
            role: profile.role,
            language_code: profile.language_code,
            permissions: profile.permissions,
            photo_asset_key: profile.photo_asset_key,
            enabled_optins: profile.enabled_optins,
            enable_sms_notifications: profile.enable_sms_notifications,
            teammate_experience: profile.teammate_experience,
            teammate_profile_publish_all_events: profile.teammate_profile_publish_all_events,
            created_at: profile.created_at
        }
        this.currentUserContextChangedBS.next(userContext);

        return userContext;
    }

    public async trySignIn(): Promise<void> {
        if (this.isAuthenticated) return;
        else {
            await this.renewAccessToken();
            await this.getUserContext();
            return;
        }
    }

    public async getAccessToken(): Promise<string> {
        if (this.apiToken) {
            if (this.apiToken.hasExpired()) {
                await this.renewAccessToken();
            }
            return this.apiToken.access_token;
        } else {
            throw AUTH_ERROR.NOT_AUTHENTICATED;
        }
    }

    public async renewAccessToken() {

        const storedApiToken: ApiToken | undefined = (() => {
            if (localStorage.getItem('apiToken')) {
                const value = JSON.parse(localStorage.getItem('apiToken')!);
                return new ApiToken(value.access_token, value.token_type, new Date(value.expires_on));
            }
            return undefined;
        })();
        if (!storedApiToken || storedApiToken.hasExpired()) {
            try {
                const token = await this.accountService.renewAccessToken().toPromise();
                if (token) {
                    this.apiToken = ApiToken.fromToken(token.access_token, token.token_type, token.expires_in);
                    localStorage.setItem('apiToken', JSON.stringify(this.apiToken));
                }
            }
            catch (ex) {
                const exception = ex as HttpErrorResponse;
                if (exception.status === 400) {
                    throw AUTH_ERROR.INVALID_SESSION;
                }
            }
        }
        else {
            this.apiToken = storedApiToken;
        }
    }

    public async signOut(returnUrl?: string) {
        await this.accountService.signout().toPromise();
        this.clearContext();
        this.router.navigate(NavigationService.AuthRoutes.SignIn(), { queryParams: { returnUrl } });
    }

    private clearContext() {
        localStorage.removeItem('apiToken');
        this.apiToken = undefined;
        this.currentUserContextChangedBS.next(null);
    }

    public async requestVerificationCode(command: GenerateEmailVerificationCodeCommandDTO) {
        await this.accountService.requestVerificationCode(command);
    }
}

export enum AUTH_ERROR {
    NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
    INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
    INVALID_SESSION = "INVALID_SESSION"
}

export class ApiToken {
    constructor(public access_token: string, public token_type: string, public expires_on: Date) { }

    static fromToken(access_token: string, token_type: string, expires_in: number): ApiToken {
        const expires_on = new Date();
        expires_on.setSeconds(expires_on.getSeconds() + expires_in);
        return new ApiToken(access_token, token_type, expires_on);
    }

    public hasExpired(): boolean {
        return new Date() > this.expires_on;
    }
}

export interface UserContext {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    date_of_birth: LocalDate;
    has_password: boolean;
    enable_2fa: boolean;
    role: ApplicationRole;
    language_code: PlatformLanguageCode;
    permissions: ApplicationPermission[];
    photo_asset_key?: string;
    enabled_optins: string[];
    enable_sms_notifications: boolean;
    teammate_experience: string;
    teammate_profile_publish_all_events: boolean;
    created_at?: Date;
}
